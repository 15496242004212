<template>
  <q-dialog v-model="show" persistent>
    <q-card class="form">
      <q-card-section class="text-center text-white text-bold bg-light-blue-8">
        Login User
      </q-card-section>

      <q-card-section class="">
        <q-input
          outlined
          v-model="user.username"
          id="id"
          placeholder="Email / Username"
          @keyup.enter="$refs.pass.focus()"
          dense
        >
          <template v-slot:append>
            <q-icon name="account_circle" @click="text = ''" class="cursor-pointer" />
          </template>
        </q-input>
        <q-input
          outlined
          v-model="user.password"
          ref="pass"
          :type="isPwd ? 'password' : 'text'"
          id="password"
          placeholder="Password"
          @keyup.enter="login()"
          dense
        >
          <template v-slot:append>
            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
          </template>
        </q-input>
        
      </q-card-section>
      <q-card-actions align="center" class="row q-pa-md justify-between">
        <q-btn outline style="width: 120px;" color="light-blue-8" label="Batal" @click="$router.go(-1)" no-caps />
        <q-btn  style="width: 120px;color:white;" color="light-blue-8" label="Masuk"  no-caps @click="login()" />
        <div class="row text-grey-8 q-pt-sm justify-center items-center full-width">
          Belum punya akun?
          <q-btn
            flat
            color="primary"
            class="q-px-xs"
            dense
            no-caps
            @click="$router.push('/buat-akun')"
            label="daftar"
          />
        </div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import md5 from "md5";
import { mapState, mapActions } from "vuex";
export default {
  name: "Login",
  data: function() {
    return {
      user: {},
      isPwd: true,
      show: true,
    };
  },
  methods: {
    ...mapActions(["getCurrentUser"]),
    login() {
      var usr = {};
      usr.username = this.user.username;
      usr.password = md5(this.user.password);
      this.$http
        .post("/login", usr, {})
        .then(async (result) => {
          await localStorage.setItem("smp-afbs", result.data);
          await this.getCurrentUser()

          // if (this.$q.screen.width < 500) await this.$router.push("/pendaftaran");
          // else await this.$router.push("/pendaftaran");

          await this.$q.notify({
            icon: "done",
            color: "positive",
            message: "Anda berhasil login",
          });
          await this.$router.push("/pendaftaran");

        })
        .catch((err) => {
          this.$q.notify({
            icon: "warning",
            color: "negative",
            message: "Username atau password salah",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  width: 300px;
}
label {
  padding-top: 10px;
  padding-bottom: 15px;
  color: #505050;
  font-weight: bold;
}
</style>
